import { APIFilter } from '@/utils/api'

export default {
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idsobre_destinatario', id)
    const resp = await Vue.$api.call('sobreDestinatario.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
