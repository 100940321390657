export default {
  async insert (Vue, formData, idsobre) {
    const resp = await Vue.$api.call(
      'sobreDestinatario.insert',
      {
        values: {
          estado: 1,
          idsobre: idsobre,
          clasificacion: formData.clasificacion,
          email: formData.email,
          manual: 1,
          notifica_sat: formData.notifica_sat,
          notifica_comercial: formData.notifica_comercial,
          notifica_admon: formData.notifica_admon,
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}
